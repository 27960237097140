import './App.css';
import './Mobile.css'
import Login_Signup from './pages/Login_Signup';
import { Route, Routes } from 'react-router-dom';
import Protected from './guards/Protected';
import Authentic from './guards/Authentic';
import Dashboard from './pages/Dashboard'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsCondition from './pages/TermsCondition'
import FAQ from './pages/Faq';
import EditFaq from "./pages/EditFaq"
import Industry from './pages/Industry';
import Users from './pages/Users';
import CompanySize from './pages/CompanySize';
import WorkType from './pages/WorkTypes';
import WorkDuration from './pages/WorkDuration';
import JoinCompany from './pages/JoinCompany';
import Residency from './pages/Residency';
import WorkEnvironment from './pages/WorkEnviroment';
import JobRequirement from './pages/JobRequirement';
import Language from './pages/Language';
import Attributes from './pages/Attributes';
import Shift from './pages/Shift';
import NoticePeriod from './pages/NoticePeriod';
import Skills from './pages/Skills';
import SeekingJob from './pages/SeekingJob';
import AboveIndustry from './pages/AboveIndustry';
import DescribesYou from './pages/DescribesYou';
import PageNotFound from './pages/PageNotFound';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/Profile';
import ChangePassword from "./pages/ChangePassword"
import Education from './pages/Education';
import UserDetails from './pages/UserDetails';
import Radius from './pages/Radius';

function App() {


  return (
    <>
      <Routes>
        <Route exact path='/login' element={<Authentic><Login_Signup /></Authentic>} />
        <Route exact path='/forgot-password' element={<Authentic><ForgotPassword /></Authentic>} />

        <Route  element={<Protected />}>
          <Route exact path='/' element={<Dashboard />} />
          <Route exact path='/job-industry' element={<Industry />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-and-condition' element={<TermsCondition />} />
          <Route exact path='/faq' element={<FAQ />} />
          <Route exact path='/my-profile' element={<Profile />} />
          <Route exact path='/change-password' element={<ChangePassword />} />
          <Route exact path='/edit-faq/:id' element={<EditFaq />} />
          <Route exact path='/all-users' element={<Users />} />
          <Route exact path='/education-level' element={<Education />} />
          <Route exact path='/residency-status' element={<Residency />} />
          <Route exact path='/user-details/:id' element={<UserDetails />} />
          <Route exact path='/job-requirement' element={<JobRequirement />} />
          <Route exact path='/languages' element={<Language />} />
          <Route exact path='/attributes' element={<Attributes />} />
          <Route exact path='/skills' element={<Skills />} />
          <Route exact path='/seeking-job' element={<SeekingJob />} />
          <Route exact path='/radius' element={<Radius />} />
          <Route exact path='/above-industry' element={<AboveIndustry />} />
          <Route exact path='/describes-you' element={<DescribesYou />} />
        </Route>

        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
      
    </>
  );
}

export default App;