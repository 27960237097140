import React, { useEffect, useState } from 'react'
import { Block, Delete, Eye, Left, Right, Search } from '../common/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../apis/functions'
import APIS from '../apis/routes'
import NoDataRow from '../common/NoDataRow'
import toast from 'react-hot-toast'
import Loader from '../common/Loader'
import NewPagination from '../common/NewPagination'
import { useSelector } from 'react-redux'
import Button from '../common/Button'



export default function Users() {
    const [all, setAll] = useState([]);
    const nav = useNavigate()
    const [row, setRow] = useState(10);
    const [skip, setSkip] = useState(0)
    const [key, setKey] = useState("")
    const [load, setLoad] = useState(true);
    const [number, setNumber] = useState();
    const [filter, setFilter] = useState('')
    const [id, setId] = useState('')
    const info = useSelector(state => state.count.counts)

    const handleFilter = (e) => {
        setFilter(e.target.value)
    }



    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.GET_ALL_USERS}?value=${key}&filter=${filter}&row=${row}&skip=${skip}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
            } else {
                setAll(result.data.data)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }



    const blockUnblockUser = async () => {
        try {
            const result = await patchRequest(`${APIS.BLOCK_UNBLOCK_USER}${id}`, {});
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const ArchivedUnArchivedUser = async () => {
        try {
            const result = await patchRequest(`${APIS.ARCHIVED_UN_ARCHIVED_USER}${id}`, {});
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }
    const handleRow = (e) => {
        setRow(e.target.value)
    }
    const handlePreviousPage = () => {
        if (skip - row >= 0) {
            setSkip(skip - row);
        }
    };

    const handleNextPage = () => {
        const totalUsers = info.user_count;
        if (skip + row < totalUsers) {
            setSkip(skip + row);
        }
    };

    useEffect(() => {
        fetchData();
    }, [key, filter, skip, row])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle mt-4">
                        Users Management <br />
                    </div>
                </div>
                <div className="col-lg-6">
                    <input type="text" placeholder={'Search here'} onChange={(e) => setKey(e.target.value)} icon={<Search />} className='search_input mt-3' />
                    {/* <Search_input  placeholder={'Search here'} search={key}  onChange={(e)=>setKey(e.target.value)} icon={<Search />} className='search_input mt-3' /> */}
                </div>
                <div className="col-lg-6">
                </div>
                <div className="col-lg-3 offset-lg-3 mt-4">
                    Filter-:&nbsp;&nbsp;
                    <select onChange={handleFilter}>
                        <option value="">All</option>
                        <option value="USER">Users</option>
                        <option value="COMPANY">Companies</option>
                    </select>
                </div>
            </div>
            <div className="row mt-5">
                <>
                    {load ?
                        <Loader />
                        :
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colspan="4">
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Join As </th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {all.length > 0
                                        ?
                                        all.map((element, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td style={{ textTransform: "inherit" }}>{element.name}</td>
                                                <td>{element.email}</td>
                                                <td>
                                                    {element.user_status === 'USER' ?
                                                        <span class="user-status active">User</span>
                                                        :
                                                        <span class="user-status pending">Company</span>
                                                    }
                                                </td>
                                                <td>{element.country_code + element.phone_number}</td>
                                                <td>{element.status === 1 ? 'Active' : element.status === 2 ? 'Deleted' : 'Blocked'}</td>
                                                <td>
                                                    <div className="actions">
                                                        <button className='view p-2'>
                                                            <Link className='view' to={'/user-details/' + element._id}>
                                                                <Eye />
                                                            </Link>
                                                        </button>
                                                        <button className='block' data-bs-toggle="modal" data-bs-target="#block_user_model" onClick={() => { setId(element._id); setNumber(element.status) }} style={{ color: (element.status === 1 ? "#0F993E" : "#ff4c27") }}>
                                                            <Block />
                                                        </button>
                                                        <button className='delete' data-bs-toggle="modal" data-bs-target="#archived_user_model" onClick={() => { setId(element._id); setNumber(element.status) }} style={{ color: (element.status === 2 ? "#0F993E" : "#ff4c27") }}>
                                                            <Delete />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : <NoDataRow />}
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            </div>
            {info.user_count === row || key !== "" ? null :
                (<div className='d-flex gap-3 justify-content-center align-items-center w-100'>
                    <button onClick={handlePreviousPage} disabled={skip <= 0}>Previous</button>
                    <button onClick={handleNextPage} disabled={skip > all.length}>Next</button>
                </div>)}
            <div class="modal fade" id="archived_user_model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-body text-center">
                            {/* <div className="modal_icon">
                            <Cancel />
                        </div> */}
                            <div className="modal_msg">
                                {number === 2 ? 'Are you sure want to UnArchive this user?' : 'Are you sure want to Archive this user?'}
                            </div>
                            <div className="modal_btns ">
                                <button type='button'  data-bs-dismiss="modal">No</button>
                                <button className='purple' data-bs-dismiss="modal" onClick={ArchivedUnArchivedUser}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="block_user_model" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-body text-center">
                            {/* <div className="modal_icon">
                            <Cancel />
                        </div> */}
                            <div className="modal_msg">
                                {number === 0 ? 'Are you sure want to unBlock this user?' : 'Are you sure want to Block this user'}
                            </div>
                            <div className="modal_btns ">
                                <button type='button'  data-bs-dismiss="modal">No</button>
                                <button className='purple' data-bs-dismiss="modal" onClick={blockUnblockUser}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
