import { createSlice } from "@reduxjs/toolkit";

const initialState = {
}

export const detailsSlice = createSlice({
    name: 'details',
    initialState: initialState,
    reducers: {
        setDetails: (state, action) => {
            state.initialState = action.payload;
        },
    }
})

export const { setDetails } = detailsSlice.actions

export default detailsSlice.reducer;