import React, { useEffect, useState } from 'react'
import { Delete, Pencil } from '../common/Icons'
import { useNavigate } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest, postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import NoDataRow from '../common/NoDataRow'
import toast from 'react-hot-toast'
import Loader from '../common/Loader'
import Input from '../common/Input'


const CompanySize = () => {
    const [size, setSize] = useState([]);
    const nav = useNavigate()
    const [load, setLoad] = useState(true);
    const [create, setCreate] = useState(false);
    const [id, setId] = useState('')
    const [edit, setEdit] = useState(false)
    const [details, setDetails] = useState({
        title: "",
        type: "company_size"
    })

    const handleChanges = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const cancelCreate = () => {
        setCreate(false)
        setEdit(false)
        setDetails({ title: '' })
    }

    const fetchData = async () => {
        try {
            const result = await getRequest(APIS.GET_ALL_COMPANY_SIZE);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
            } else {
                setSize(result.data.data)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    const deletetitle = async () => {
        try {
            const result = await postRequest(`${APIS.DELETE_FIELD}`,{id:id});
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
            } else {
                toast.success(result.data.message)
                fetchData()
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    const addTitle = async () => {
        try {
            const result = await postRequest(`${APIS.CREATE_FIELD}`, details);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                fetchData()
                setCreate(false)
                setDetails({
                    title: "",
                    type: "company_size"
                })
            }
        } catch (err) {
            toast.error(err.message);
        }
    }


    const getSingle = async (titleId) => {
        try {
            setLoad(true)
            setId(titleId)
            const result = await postRequest(`${APIS.GET_SINGLE_FIELD}`, { id: titleId });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
                setEdit(true)
                setLoad(false)
            }

        } catch (err) {
            toast.error(err.message);
        }
    }

    const updateTitle = async () => {
        try {
            const result = await patchRequest(`${APIS.EDIT_FIELD}`, { ...details, id: id });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                setEdit(false)
                fetchData()
                setDetails({
                    title: "",
                    type: "company_size"
                })
            }

        } catch (err) {
            toaster(err.message, false);
        }
    }



    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle mt-4">
                        {create ? 'Create New Company Size' : 'Company Sizes Management'}
                    </div>
                </div>
                <div className="col-lg-6">
                    {create || edit ? null : <button onClick={() => { setCreate(true) }} className='mt-3 edit_btn ms-auto d-flex align-items-center'>Create New</button>}
                </div>
            </div>
            <div className="row mt-5">
                {create ?
                    <div className={"row "}>
                        <div className="col-lg-12 col-md-12">
                            <ul className="profile_list p-0">
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Enter Title</div>
                                        <div className="name mt-2">
                                            <Input type="text" placeholder='title' value={details.title} name="title" onChange={handleChanges} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="detail_wrapper d-flex align-items-center gap-2">
                                        <button onClick={addTitle} className='edit_btn ml-auto d-flex align-items-center'>Create</button>
                                        <button onClick={cancelCreate} className='edit_btn ml-auto d-flex align-items-center'>Cancel</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    : edit ?
                        <>
                            <div className={"row "}>
                                <div className="col-lg-12 col-md-12">
                                    <ul className="profile_list p-0">
                                        <li >
                                            <div className="detail_wrapper">
                                                <div className="small_heading">Edit Title</div>
                                                <div className="name mt-2">
                                                    <Input type="text" placeholder='title' value={details.title} name="title" onChange={handleChanges} />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="detail_wrapper d-flex align-items-center gap-2">
                                                <button onClick={updateTitle} className='edit_btn ml-auto d-flex align-items-center'>Save</button>
                                                <button onClick={cancelCreate} className='edit_btn ml-auto d-flex align-items-center'>Cancel</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {load ?
                                <Loader />
                                :
                                <div className="table-responsive">
                                    <table className='table table-lg'>
                                        <thead className='table-light'>
                                            <tr colspan="4">
                                                <th>S.No</th>
                                                <th>Title Name</th>
                                                <th>action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {size.length > 0
                                                ?
                                                size.map((element, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textTransform: "inherit" }}>{element.title}</td>
                                                        <td>
                                                            <div className="actions">
                                                                <button className='view' onClick={() => { getSingle(element._id) }}>
                                                                    <Pencil />
                                                                </button>
                                                                {/* <button className='delete' data-bs-toggle="modal" data-bs-target="#delete_faq_modal" onClick={() => { setId(element._id) }}>
                                                                    <Delete />
                                                                </button> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <NoDataRow />}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </>}
            </div>
            <div class="modal fade" id="delete_faq_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-body text-center">
                            {/* <div className="modal_icon">
                    <Cancel />
                </div> */}
                            <div className="modal_msg">
                                Are you sure want to delete this company size?
                            </div>
                            <div className="modal_btns ">
                                <button type='button' onClick={deletetitle} data-bs-dismiss="modal">Yes</button>
                                <button className='purple' data-bs-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanySize
