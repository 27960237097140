import React, { useEffect, useState } from 'react'
import EditButton from '../common/Edit_button'
import { Back } from '../common/Icons'
import PropertyCard from '../common/PropertyCard'
import DetailsViewer from '../common/DetailsViewer'
import { useNavigate, useParams } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest, postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import NotFound from '../common/NotFound'
import EditProfile from '../common/EditProfile'
import toast from 'react-hot-toast'
import Input from '../common/Input'

export default function Customer_details() {
    const router = useNavigate();
    const { id } = useParams();
    const [toggle, setToggle] = useState(false);
    const [details, setDetails] = useState({
        question:"",
        answer:""
    })

    const handleChanges = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const getSingle = async () => {
        try {
            const result = await getRequest(`${APIS.GET_SINGLE_FAQ}${id}`, {});
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    router('/login')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
            }

        } catch (err) {
            toast.error(err.message);
        }
    }

    const updateProfile = async () =>{
        try {
            const result = await patchRequest(`${APIS.UPDATE_SINGLE_FAQ}`,{...details,id:id});
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    router('/login')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                setToggle(false)
            }

        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        getSingle()
        window.scroll(0, 0)
    }, [id])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center' onClick={() => {
                            router(-1)
                        }}><span><Back /></span>Faq Details</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                {!toggle?<button onClick={()=>{setToggle(!toggle)}} className='edit_btn ms-auto d-flex align-items-center'>Edit</button>:null}
                </div>
            </div>
            <div className="outer_div mt-4">
                <div className={"row "}>
                    <div className="col-lg-12 col-md-12">
                        <ul className="profile_list p-0">
                            <li >
                                <div className="detail_wrapper">
                                    <div className="small_heading">Question</div>
                                    <div className="name mt-2">
                                        {toggle ?
                                            <Input type="text" placeholder='Question' value={details.question} name="question" onChange={handleChanges}/>
                                            :
                                            <>
                                                {details.question}
                                            </>
                                        }
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div className="detail_wrapper">
                                    <div className="small_heading">Answer</div>
                                    <div className="name mt-2 text-lowercase">
                                        {toggle ?
                                            <Input type="text" placeholder='Answer' value={details.answer} name="answer" onChange={handleChanges}/>
                                            :
                                            <>
                                                {details.answer}
                                            </>
                                        }
                                    </div>
                                </div>
                            </li>
                            <li>
                            <div className="detail_wrapper">
                                {toggle ?
                                    <button onClick={updateProfile} className='edit_btn ml-auto d-flex align-items-center'>Save</button>:null
                                }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
