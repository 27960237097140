import React, { useEffect, useState } from "react";
import { getRequest, patchRequest, postRequest } from "../apis/functions";
import API, { BASE_URL } from "../apis/routes"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDetails } from "../redux/slices/detailsSlice";
import toast from "react-hot-toast";
import { Camera, EditICON, Pencil } from "../common/Icons";
import Loader from "../common/Loader";

const Profile = () => {
    const [edit, setEdit] = useState(false);
    const [load, setLoad] = useState(true);
    const [showImage, setShowImage] = useState("");
    const [file, setFile] = useState("");
    const [data, setData] = useState({ name: "", profile_pic: "" });
    const nav = useNavigate()
    const dispatcher = useDispatch()

    const addData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const getData = async () => {
        try {
            const result = await getRequest(API.GET_ADMIN_PROFILE);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                setData(result.data.data)
                dispatcher(setDetails(result.data.data))
                localStorage.setItem('info', JSON.stringify(result.data.data))
                setLoad(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const updateUser = async () => {
        try {
            if (data.name === "") {
                toast.error("Name not allowed to be empty!");
            } else {
                const formData = new FormData();
                formData.append("name", data.name);
                formData.append("profile_pic", file === "" ? data.profile_pic : file);
                const result = await patchRequest(API.EDIT_ADMIN_PROFILE, formData);
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.clear()
                        nav('/')
                        toast.error(result.data.message)
                        window.location.reload()
                    }
                    toast.error(result.data.message)
                } else {
                    setLoad(false)
                    setEdit(false);
                    getData();
                    setShowImage('')
                    toast.success(result.data.message);

                }
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            toast.error("Something went wrong");
        }
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const handleImage = (e) => {
        setShowImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="top_bar row align-items-center mt-4">
                <div className="col-lg-6">
                    <div className="pagetitle">Hello, Admin!</div>
                </div>
                <div className="col-lg-6">
                    <div className="d-flex gap-2 justify-content-end align-items-center w-100">
                        {edit ? null : (
                            <div className="editBtn d-flex gap-1" onClick={handleEdit}>
                                <EditICON />
                                Edit
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {load ?
                <Loader />
                :
                <>

                    <div className="whiteProfilePage">
                        <div className="profileDetails">
                            {edit ? (
                                <>
                                    {showImage === "" ? (
                                        <>
                                            <label htmlFor="profilePic">
                                                <div className="imageSet">

                                                    {data.profile_pic !== "" ? (
                                                        <img src={BASE_URL + '/' + data.profile_pic} alt="profileImage" className="adminProfilePic" />
                                                    ) : (
                                                        <img src="/user.jpg" alt="profileImage" className="adminProfilePic" />
                                                    )}

                                                    <div className="imageChange"><i className="bi bi-camera"></i></div>
                                                </div>
                                            </label>
                                            <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="profilePic">
                                                <div className="imageSet">
                                                    <img src={showImage} alt="profileImage" className="adminProfilePic" />
                                                    <div className="imageChange">
                                                        <i className="bi bi-camera"></i>
                                                    </div>
                                                </div>
                                            </label>
                                            <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*"
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {data.profile_pic !== "" ? (
                                        <img
                                            src={BASE_URL + '/' + data.profile_pic}
                                            alt="profileImage"
                                            className="adminProfilePic"
                                        />
                                    ) : (
                                        <img
                                            src="/user.jpg"
                                            alt="profileImage"
                                            className="adminProfilePic"
                                        />
                                    )}
                                </>
                            )}

                            <div className="details">
                                <div className="inputSet">
                                    <h6 className="mt-2">Name</h6>
                                    {!edit ? (
                                        <input type="text" disabled placeholder="Enter you name..." value={data.name} name="name" id="name" onChange={addData} />

                                    ) : (
                                        <input type="text" placeholder="Enter you name..." value={data.name} name="name" id="name" onChange={addData} />
                                    )}
                                </div>
                                <div className="inputSet">
                                    <h6 className="mt-2">Email Address</h6>
                                    <input type="text" disabled placeholder="Enter you name..." value={data.email} name="name" id="name" onChange={addData} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {edit ? (
                        <div
                            className="modal_btns"
                            style={{ justifyContent: "flex-start" }}
                            onClick={updateUser}
                        >
                            <button className="purple">Save</button>
                        </div>
                    ) : null}
                </>}
        </>
    );
};

export default Profile;
