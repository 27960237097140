import React, { useEffect, useState } from 'react'
import { Delete, Pencil } from '../common/Icons'
import { useNavigate } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest, postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import NoDataRow from '../common/NoDataRow'
import toast from 'react-hot-toast'
import Loader from '../common/Loader'
import Input from '../common/Input'


const Radius = () => {
    const [size, setSize] = useState([]);
    const nav = useNavigate()
    const [load, setLoad] = useState(true);
    const [create, setCreate] = useState(false);
    const [id, setId] = useState('')
    const [edit, setEdit] = useState(false)
    const [details, setDetails] = useState({
        radius: 500,
    })

    const handleChanges = (e) => {
        if (!isNaN(e.target.value)) {
            setDetails({
                ...details,
                [e.target.name]: e.target.value
            })
        }
    }

    const cancelCreate = () => {
        setCreate(false)
        setEdit(false)
        setDetails({ title: '' })
    }

    const fetchData = async () => {
        try {
            const result = await getRequest(APIS.GET_RADIUS);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
            } else {
                setSize(result.data.data)
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }


    const addTitle = async () => {
        try {
            const result = await postRequest(`${APIS.CREATE_FIELD}`, details);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                fetchData()
                setCreate(false)
                setDetails({
                    title: "",
                    type: "why_seeking_new_job"
                })
            }
        } catch (err) {
            toast.error(err.message);
        }
    }


    const getSingle = async (titleId) => {
        try {
            setLoad(true)
            setId(titleId)
            const result = await postRequest(`${APIS.GET_SINGLE_FIELD}`, { id: titleId });
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                setDetails(result.data.data)
                setEdit(true)
                setLoad(false)
            }

        } catch (err) {
            toast.error(err.message);
        }
    }

    const updateTitle = async () => {
        try {
            const result = await patchRequest(`${APIS.EDIT_RADIUS}`,details);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message)
                setEdit(false)
                fetchData()
            }

        } catch (err) {
            toaster(err.message, false);
        }
    }

    const editFunction = (data) => {
        setEdit(true)
        setDetails(data)
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle mt-4">
                        Radius Management
                    </div>
                </div>
                <div className="col-lg-6">
                </div>
            </div>
            <div className="row mt-5">
                {edit ?
                    <>
                        <div className={"row "}>
                            <div className="col-lg-12 col-md-12">
                                <ul className="profile_list p-0">
                                    <li >
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Edit Radius</div>
                                            <div className="name mt-2">
                                                <Input type="text" placeholder='radius' value={details.radius} name="radius" onChange={handleChanges} />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper d-flex align-items-center gap-2">
                                            <button onClick={updateTitle} className='edit_btn ml-auto d-flex align-items-center'>Save</button>
                                            <button onClick={cancelCreate} className='edit_btn ml-auto d-flex align-items-center'>Cancel</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {load ?
                            <Loader />
                            :
                            <div className="table-responsive">
                                <table className='table table-lg'>
                                    <thead className='table-light'>
                                        <tr colspan="4">
                                            <th>S.No</th>
                                            <th>Radius Field</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {size.length > 0
                                            ?
                                            size.map((element, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textTransform: "inherit" }}>{element.radius}</td>
                                                    <td>
                                                        <div className="actions">
                                                            <button className='view' onClick={() => { editFunction(element) }}>
                                                                <Pencil />
                                                            </button>
                                                            {/* <button className='delete' data-bs-toggle="modal" data-bs-target="#delete_faq_modal" onClick={() => { setId(element._id) }}>
                                                                    <Delete />
                                                                </button> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                            : <NoDataRow />}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>}
            </div>

        </>
    )
}

export default Radius
