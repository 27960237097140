// const BASE_URL = 'http://localhost:6014'
const BASE_URL = 'https://app.empmatch.com/'

const routes = {
    LOGIN: "/admin/login",
    GET_ADMIN_PROFILE: "/admin/get_admin_profile",
    EDIT_ADMIN_PROFILE: "/admin/edit_admin_profile",
    CHANGE_PASSWORD: '/admin/change_password',

    //content section
    UPDATE_CONTENT: "/admin/update_privacy_policy",
    UPDATE_CONTENT_TERMS: "/admin/update_terms_conditions",
    GET_TERMS_AND_CONDITIONS: "/admin/getTerms",
    GET_ALL_FAQ: "/admin/faq_listing",
    GET_SINGLE_FAQ: "/admin/get_single_faq/",
    UPDATE_SINGLE_FAQ: "/admin/update_faq",
    DELETE_FAQ: "/admin/delete_faq/",
    CREATE_FAQ: "/admin/add_faq",

    //dashboard section
    GET_ALL_COUNT: "/admin/get_all_counts",
    //user section
    GET_ALL_USERS: "/admin/get_all_user",
    GET_USER_DETAILS: "/admin/get_user_details",
    BLOCK_UNBLOCK_USER: "/admin/block_user/",
    ARCHIVED_UN_ARCHIVED_USER: "/admin/archived_user/",

    //jobs section
    CREATE_NEW_INDUSTRY: "/admin/add_job_industry",
    GET_ALL_INDUSTRY: "/admin/show_job_industry",
    GET_SINGLE_JOB_INDUSTRY: "/admin/get_single_job_industry/",
    UPDATE_JOBS_INDUSTRY: "/admin/update_job_industry",
    DELETE_INDUSTRY: "/admin/delete_job_industry/",

    //dynamic fields section
    GET_ALL_COMPANY_SIZE: '/admin/get_all_company_size',
    GET_ALL_WORK_TYPE: '/admin/get_all_type_work',
    GET_ALL_WORK_DURATION: '/admin/get_all_work_duration',
    GET_ALL_JOIN_TIME: '/admin/get_all_join_time',
    GET_ALL_RESIDENCY: '/admin/get_all_residency',
    GET_ALL_ENVIRONMENT: '/admin/get_all_environment',
    GET_ALL_JOBS_REQUIREMENT: '/admin/get_all_requirement',
    GET_ALL_LANGUAGE: '/admin/get_all_language',
    GET_ALL_ATTRIBUTES: '/admin/get_all_attributes',
    GET_ALL_SHIFT: '/admin/get_all_shifts',
    GET_ALL_NOTICE: '/admin/get_all_notice',
    GET_ALL_SKILLS: '/admin/get_all_skills',
    GET_ALL_SEEKING_JOB: '/admin/get_all_seeking_job',
    GET_ALL_ABOVE_INDUSTRY: '/admin/get_all_above_industry',
    GET_ALL_DESCRIBES_YOU: '/admin/get_all_describes_you',
    GET_RADIUS: '/admin/get_radius',
    EDIT_RADIUS: '/admin/edit_radius',



    EDIT_FIELD: '/admin/edit_fields',
    CREATE_FIELD: '/admin/create_fields',
    DELETE_FIELD: '/admin/delete_fields',
    GET_SINGLE_FIELD: '/admin/get_single_fields',

    //education
    GET_ALL_EDUCATION: '/admin/get_all_education',
    CREATE_EDUCATION: '/admin/create_education',
    EDIT_EDUCATION: '/admin/edit_education',
    GET_SINGLE_EDUCATION: '/admin/get_single_education',
}


export default routes;
export { BASE_URL }