import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Login_Signup from '../pages/Login_Signup';

const Authentic = () => {
  var auth = localStorage.getItem("x-token")
  return (
    <>
      {
        auth
          ?
          <Navigate to="/" />
          :
          <Navigate to="/login" />
      }
    </>

  )
}

export default Authentic