import React, { useEffect, useState } from "react";
import { Back, EditICON } from "../common/Icons";
import {
    NavLink,
    useLocation,
    useNavigation,
    useParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../common/Loader";
import { getRequest, postRequest } from "../apis/functions";
import toast from "react-hot-toast";
import API, { BASE_URL } from "../apis/routes";


export default function UserDetails() {
    const router = useParams();
    const leave = useNavigate();
    const [load, setLoad] = useState(false);
    const nav = useNavigate();
    const [data, setData] = useState({});




    const getData = async () => {
        try {
            const result = await postRequest(`${API.GET_USER_DETAILS}`, { id: router.id })
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/')
                    toast.error(result.data.message)
                    window.location.reload()
                }
                toast.error(result.data.message)
            } else {
                setData(result.data.data)
                setLoad(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };




    useEffect(() => {
        getData();
        window.scroll(0, 0)
    }, []);



    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle mt-4">
                    <button className='d-flex align-items-center' onClick={() => {
                            leave(-1)
                        }}><span><Back /></span>User Details</button>
                    </div>
                </div>
                <div className="col-lg-6">
                </div>
            </div>
            {load
                ?
                <Loader /> :
                <>
                    <div className="outer_div mt-4">
                        <div className="row border-bottom">
                            <div className="col-lg-2 col-md-2">
                                <div className="profile_image">
                                    {data.profile_pic !== "" ? (
                                        <img src={BASE_URL + '/uploads/' + data.profile_pic} alt="profileImage" className="customerProfilePic" />
                                    ) : (
                                        <img src="/user.jpg" alt="profileImage" className="customerProfilePic" />
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10">
                                <ul className="profile_list p-0">
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">User Status</div>
                                            <div className="name mt-2">
                                                {data.status === 1 ?
                                                    <span class="user-status active">Active</span>
                                                    : data.status === 2 ?
                                                        <span class="user-status inactive">Deleted</span>
                                                        :
                                                        <span class="user-status pending">Blocked</span>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Name</div>
                                            <div className="name mt-2">{data.name}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Email Address</div>
                                            <div className="name mt-2">{data.email}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">joining date</div>
                                            <div className="name mt-2">
                                                {moment(data.timestamp).format('LLL')}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Contact Info</div>
                                            <div className="name mt-2">
                                                +{data.country_code + data.phone_number}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Date of Birth</div>
                                            <div className="name mt-2">
                                                {data.dob}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Join As</div>
                                            <div className="name mt-2">
                                                {data.user_status === 'USER' ?
                                                    <span class="user-status active">User</span>
                                                    :
                                                    <span class="user-status pending">Company</span>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
}
