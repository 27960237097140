import React from 'react'
import { Home, Service, User } from './Icons'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Sidebar() {
    const info = useSelector(state => state.details)

    return (
        <>
            <aside id='sidebar' className='sidebar'>
                <ul className='sidenav' id='sidenav'>
                    <div className="menu_title">Dashboard</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/" className="nav-link">
                            <span><Home /></span>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">User Management</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/all-users" className="nav-link">
                            <span><User /></span>
                            <span>All Users</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">Job Management</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/job-industry" className="nav-link">
                            <span><i className="fa-solid fa-industry"></i></span>
                            <span>Jobs Industries</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">Fields Management</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/residency-status" className="nav-link">
                            <span><i className="fa-solid fa-house"></i></span>
                            <span>Residency Status</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/job-requirement" className="nav-link">
                            <span><i className="fa-solid fa-user-doctor"></i></span>
                            <span>Job Requirement</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/education-level" className="nav-link">
                            <span><i class="fa-solid fa-turn-up"></i></span>
                            <span>Education level</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/languages" className="nav-link">
                            <span><i className="fa-solid fa-language"></i></span>
                            <span>Languages</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/attributes" className="nav-link">
                            <span><i className="fa-brands fa-creative-commons-by"></i></span>
                            <span>Attribute</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/skills" className="nav-link">
                            <span><i className="fa-solid fa-book"></i></span>
                            <span>Skills</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/radius" className="nav-link">
                            <span><i className="fa-solid fa-earth-americas"></i></span>
                            <span>Radius</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/seeking-job" className="nav-link">
                            <span><i className="fa-solid fa-people-robbery"></i></span>
                            <span>Seeking Jobs</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/above-industry" className="nav-link">
                            <span><i className="fa-solid fa-industry"></i></span>
                            <span>Above Industry</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/describes-you" className="nav-link">
                            <span><i className="fa-solid fa-audio-description"></i></span>
                            <span>Describes You</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">Content Management</div>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/faq" className="nav-link">
                            <span><Service /></span>
                            <span>Faqs</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/privacy-policy" className="nav-link">
                            <span><Service /></span>
                            <span>Privacy Policy</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact activeClassName="active" to="/terms-and-condition" className="nav-link">
                            <span><Service /></span>
                            <span>Terms & conditions</span>
                        </NavLink>
                    </li>
                </ul>
            </aside>
        </>
    )
}


