import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setToken } from '../apis/functions';
import Image from './Image';
import { useSelector } from 'react-redux'
import { BASE_URL } from '../apis/routes';

export default function Profile_dropdown() {
    const router = useNavigate();
    
    const logout = () => {
        localStorage.clear();
        setToken("")
        router('/')
        window.location.reload()
    }

    const info = useSelector(state => state.details.initialState)

    return (
        <div className="profile">
            <div class="dropdown">
                {info && info.profile_pic !== "" ?
                    <img src={BASE_URL + '/' + info.profile_pic} alt="userDefault" className='img-fluid rounded-circle sidebar-profile' />
                    :
                    <img src="/user.jpg" alt="userDefualt" className='img-fluid rounded-circle sidebar-profile' />
                }
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {info && info.name}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li><Link class="dropdown-item" to="/my-profile">Profile</Link></li>
                    <li><Link class="dropdown-item" to="/change-password">Change Password</Link></li>
                    <li><Link class="dropdown-item" onClick={logout}>Logout</Link></li>
                </ul>
            </div>
        </div>
    )
}
