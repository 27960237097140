import React, { useState } from 'react'
import Input from '../common/Input'
import Button from '../common/Button'
import { postRequest, setToken } from '../apis/functions'
import APIS from '../apis/routes'
import {  useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDetails } from '../redux/slices/detailsSlice'
import toast from 'react-hot-toast'

export default function Login_Signup() {
    const router = useNavigate();
    const dispatcher = useDispatch();
    const [loader, setLoader] = useState(false);
    const [forgot, setForgot] = useState(false)
    const [login, setLogin] = useState({
        email: "",
        password: ""
    });

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    function handleChanges(e) {
        setLogin({
            ...login,
            [e.target.name]: e.target.value.trim()
        })
    }

    async function submitFormForgot(event) {
        // event.preventDefault()
        try {
            setLoader(true);
            toast.error('The forgot password function is currently in progress. We will acknowledge your request soon!',{duration:2000})
            setLoader(false);
            // const result = await postRequest(APIS.LOGIN, login)
            // if (!result.data.status) {
            //     if (result.data.code === 203) {
            //         localStorage.clear()
            //         router('/login')
            //     }

            // } else {
            //     localStorage.setItem('x-token', result.data.token)
            //     setToken(result.data.token)
            //     router('/')
            //     window.location.reload()
            //     dispatcher(setDetails(result.data.info));
            //     localStorage.setItem('info', JSON.stringify(result.data.info))
            // }
        } catch (err) {
            setLoader(false)
            toast.error(err.message)
        }
    };

    async function submitForm(event) {
        event.preventDefault()
        try {
            setLoader(true);
            const result = await postRequest(APIS.LOGIN, login)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    router('/login')
                }
                setLoader(false);
                toast.error(result.data.message)
            } else {
                localStorage.setItem('x-token', result.data.token)
                setToken(result.data.token)
                router('/')
                window.location.reload()
                dispatcher(setDetails(result.data.info));
                localStorage.setItem('info', JSON.stringify(result.data.info))
            }
        } catch (err) {
            setLoader(false)
            toast.error(err.message)
        }
    };

    return (
        <>
            <div className="container">
                <div className="row align-items-center h-100vh">
                    <div className="col-lg-5 col-md-5">
                        <div className="login_area">
                            <div className="logo"><img src="/logo.png" alt="logo" className='img-fluid' /></div>
                            {!forgot ?
                                <>
                                    <p className='mt-3'>Sign in your account</p>
                                    <form onSubmit={submitForm}>
                                        <div className="mt-5">
                                            <div className="group">
                                                <Input required={true} className={'mt-2'} type={'text'} id={'text'} onChange={handleChanges} onKeyDown={handleKeyDown} autoComplete={'off'} placeholder={'abc@example.com'} value={login.email} name="email" label={'Email'} />
                                            </div>
                                            <div className="group mt-4">
                                                <Input required={true} className={'mt-2'} value={login.password} id={'password'} type={'password'} name="password" onKeyDown={handleKeyDown} placeholder='********' onChange={handleChanges} label={'Password'} />
                                            </div>
                                            <div className="mt-2 text-end forget" onClick={() => setForgot(true)} style={{cursor:"pointer"}}>
                                                Forget Password
                                            </div>
                                            <div className="site_btn mt-5">
                                                <Button loader={loader} type="submit" text={'Login'} />
                                            </div>
                                        </div>
                                    </form>
                                </>
                                :
                                <>
                                    <p className='mt-3'>Forgot Your Password?</p>
                                    <form onSubmit={submitFormForgot}>
                                        <div className="mt-5">
                                            <div className="group">
                                                <Input className={'mt-2'} type={'text'} id={'text'} onChange={handleChanges} onKeyDown={handleKeyDown} autoComplete={'off'} placeholder={'abc@example.com'} value={login.email} name="email" label={'Email'} />
                                            </div>
                                            <div className="mt-2 text-end forget" onClick={() => setForgot(false)} style={{cursor:"pointer"}}>
                                                Did you remembered your password Sign In?
                                            </div>
                                            <div className="site_btn mt-5">
                                                <Button loader={loader} type="submit" text={'Submit'} />
                                            </div>
                                        </div>
                                    </form>
                                </>}
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 mobile_hide">
                        <div className="center_img text-center">
                            <img src="/login-img.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
